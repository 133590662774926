import React from 'react';
import PropTypes from 'prop-types';

import { useMap, Marker } from 'react-leaflet';

// User contributed modules
import MyMapContainer from '../map/MyMapContainer';

/** React component */
const ChangeView = ({ center, zoom }) => {
  const map = useMap();
  map.setView(center, zoom);
  return null;
}

/** React component */
const EventsFormRadiusMap = ({
  center,
  position,
  onCoordinates,
}) => {
  return (
    <MyMapContainer
      className="leaflet-radius-map"
      center={center}
      zoom={2}
    >
      <ChangeView center={center} />
      <Marker
        draggable
        eventHandlers={{
          dragend: (e) => {
            onCoordinates(e.target._latlng.lat.toFixed(3), e.target._latlng.lng.toFixed(3));
          }
        }}
        position={position}
      />
    </MyMapContainer>
  ); 
};

EventsFormRadiusMap.propTypes = {
  /**
   * Center of the map
   */
  center: PropTypes.arrayOf(PropTypes.number),
  /**
   * Marker position
   */
  position: PropTypes.arrayOf(PropTypes.number),
  /**
   * Callback routine when coordinates are update
   */
  onCoordinates: PropTypes.func.isRequired,
}

EventsFormRadiusMap.defaultProps = {
  center: [63.13, -90.34],
  position: [0, 0],
  onCoordinates: () => {},
}

export default EventsFormRadiusMap;
/**
 * @author Charles Blais <charles.blais@canada.ca>
 */

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { useTable, useSortBy } from 'react-table';
import { Table } from 'react-bootstrap';

import { useLanguageState } from '../../contexts/LanguageProvider';

/**
 * Convert the events list into a list that is react-table friendly.
 * 
 * @param {*} siteDesignations 
 */
const makeData = (events, lang) => {
  return events
  .map(event => {
    const magnitude = event.magnitude.toFixed(1);
    return {
      datetime: moment.utc(event.datetime).format('YYYY-MM-DD HH:mm:ss'),
      latitude: event.latitude.toFixed(3),
      longitude: event.longitude.toFixed(3),
      depth: event.depth.toFixed(1),
      magnitude: (event.magnitudeType)?`${magnitude} ${event.magnitudeType}`:magnitude,
      description: event.getDescription(lang)
    }
  }).sort((a, b) => (a.datetime > b.datetime) ? -1 : 1);
}

/**
 * Create react-table column information
 * @param {*} lang
 */
const makeColumns = (lang) => {
  return [{
    Header: lang.table.datetime,
    accessor: 'datetime',
  }, {
    Header: lang.table.latitude,
    accessor: 'latitude',
  }, {
    Header: lang.table.longitude,
    accessor: 'longitude',
  }, {
    Header: lang.table.depth,
    accessor: 'depth',
  }, {
    Header: lang.table.magnitude,
    accessor: 'magnitude',
  }, {
    Header: lang.table.description,
    accessor: 'description',
  }];
}


/**
 * React component extention of the TableHazard
 * ============================================
 */
 const EventsTableReact = ({
  columns,
  data,
}) => {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  }, useSortBy);

  // Render the UI for your table
  return (
    <Table bordered striped hover condensed {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
              {column.render('Header')}
                <span>
                  {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => (
                <td {...cell.getCellProps()}>
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
};

/**
 * React component
 * ===============
 */
 const EventsTable = ({
  events,
}) => {
  const [ lang ] = useLanguageState();
  const columns = useMemo(() => makeColumns(lang), [lang]);
  const data = useMemo(() => makeData(events, lang.abbr), [events, lang]);

  return (
    <EventsTableReact
      columns={columns}
      data={data}
    />
  )
};


EventsTable.propTypes = {
  events: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default EventsTable;

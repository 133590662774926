/**
 * @author Charles Blais
 */
import React from 'react';
import { Row, Col, Alert } from 'react-bootstrap';

import { useFormikContext } from 'formik';

import EventsFormElement from './EventsFormElement';

import { useLanguageState } from '../../contexts/LanguageProvider';

const EventsFormRowTime = () => {
  const [ lang ] = useLanguageState();
  const formik = useFormikContext();

  const eventtypeOptions = Object.keys(lang.form.eqtypesOptions).map(key => (
    <option key={key} value={key}>{lang.form.eqtypesOptions[key]}</option>
  ));
  const formatOptions = Object.keys(lang.form.formatOptions).map(key => (
    <option key={key} value={key}>{lang.form.formatOptions[key]}</option>
  ));

  return (
    <>
    <Row>
      <Col sm={6}>
        <EventsFormElement
          id="formStartTime"
          label={lang.form.starttime}
          controlProps={{
            name: "starttime",
            type: "datetime",
            value: formik.values.starttime,
            onChange: formik.handleChange,
          }}
          feedback={lang.form.starttimeHelp}
          isInvalid={!!formik.errors.starttime}
        />
      </Col>
      <Col sm={6}>
        <EventsFormElement
          id="formEndTime"
          label={lang.form.endtime}
          controlProps={{
            name: "endtime",
            type: "datetime",
            value: formik.values.endtime,
            onChange: formik.handleChange,
          }}
          feedback={lang.form.endtimeHelp}
          isInvalid={!!formik.errors.endtime}
        />
      </Col>
    </Row>
    <Row>
      <Col sm={3}>
        <EventsFormElement
          id="formMinMagnitude"
          label={lang.form.minmagnitude}
          controlProps={{
            name: "minmagnitude",
            type: "number",
            step: "any",
            value: formik.values.minmagnitude,
            onChange: formik.handleChange,
          }}
          feedback={lang.form.minmagnitudeHelp}
          isInvalid={!!formik.errors.minmagnitude}
        />
      </Col>
      <Col sm={3}>
        <EventsFormElement
          id="formMaxMagnitude"
          label={lang.form.maxmagnitude}
          controlProps={{
            name: "maxmagnitude",
            type: "number",
            step: "any",
            value: formik.values.maxmagnitude,
            onChange: formik.handleChange,
          }}
          feedback={lang.form.maxmagnitudeHelp}
          isInvalid={!!formik.errors.maxmagnitude}
        />
      </Col>
      <Col sm={3}>
        <EventsFormElement
          id="formMinDepth"
          label={lang.form.mindepth}
          controlProps={{
            name: "mindepth",
            type: "number",
            step: "any",
            value: formik.values.mindepth,
            onChange: formik.handleChange,
          }}
          feedback={lang.form.mindepthHelp}
          isInvalid={!!formik.errors.mindepth}
        />
      </Col>
      <Col sm={3}>
        <EventsFormElement
          id="formMaxDepth"
          label={lang.form.maxdepth}
          controlProps={{
            name: "maxdepth",
            type: "number",
            step: "any",
            value: formik.values.maxdepth,
            onChange: formik.handleChange,
          }}
          feedback={lang.form.maxdepthHelp}
          isInvalid={!!formik.errors.maxdepth}
        />
      </Col>
    </Row>
    <Row>
      <Col sm={3}>
        <EventsFormElement
          id="formEventType"
          label={lang.form.eqtypes}
          controlProps={{
            name:"eventtype",
            componentClass:"select",
            multiple: true,
            size: eventtypeOptions.length,
            value: formik.values.eventtype,
            onChange: (evt) => {
              formik.setFieldValue(
                "eventtype",
                [].slice
                  .call(evt.target.selectedOptions)
                  .map(option => option.value)
              )
            },
          }}
          isInvalid={!!formik.errors.eventtype}
        >
          {eventtypeOptions}
        </EventsFormElement>
      </Col>
      <Col sm={3}>
        <EventsFormElement
          id="formOnlyFelt"
          label={lang.form.onlyfelt}
          controlProps={{
            name:"onlyfelt",
            componentClass:"select",
            size: 2,
            value: formik.values.onlyfelt,
            onChange: formik.handleChange,
          }}
          isInvalid={!!formik.errors.onlyfelt}
        >
          <option value={false}>{lang.form.no}</option>
          <option value={true}>{lang.form.yes}</option>
        </EventsFormElement>
      </Col>
      <Col sm={3}>
        <EventsFormElement
          id="formFormat"
          label={lang.form.format}
          controlProps={{
            name:"format",
            componentClass:"select",
            size: formatOptions.length,
            value: formik.values.format,
            onChange: formik.handleChange,
          }}
          isInvalid={!!formik.errors.format}
        >
          {formatOptions}
        </EventsFormElement>
      </Col>
      <Col sm={3}>
        <EventsFormElement
          id="formLimit"
          label={lang.form.limit}
          controlProps={{
            name: "limit",
            type: "number",
            value: formik.values.limit,
            onChange: formik.handleChange,
          }}
          feedback={lang.form.limitHelp}
          isInvalid={!!formik.errors.limit}
        />
        <Alert bsStyle="info">
          {lang.form.formatNotice}
        </Alert>
      </Col>
    </Row>
    </>
  );
}

export default EventsFormRowTime;
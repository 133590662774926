/**
 * @author Charles Blais
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { Form, Button, PanelGroup, Panel, Row, Col } from 'react-bootstrap';

import EventsFormRowGeneral from './EventsFormRowGeneral';
import EventsFormRowBox from './EventsFormRowBox';
import EventsFormRowRadius from './EventsFormRowRadius';

import { useLanguageState } from '../../contexts/LanguageProvider';
import { useAppState } from '../../contexts/AppStateProvider';


/**
 * Convert form element values to FDSNWS compatible
 * @param {*} values
 * @returns {object} fdsnws like parameters 
 */
const valuesToFDSNWS = (values) => {
  // determine the parameter to send from the list of fields
  // the region type filters out certian fields
  const params = {
    starttime: moment(values.starttime).format('YYYY-MM-DDTHH:mm:ss'),
    endtime: moment(values.endtime).format('YYYY-MM-DDTHH:mm:ss'),
    mindepth: values.mindepth,
    maxdepth: values.maxdepth,
    minmagnitude: values.minmagnitude,
    maxmagnitude: values.maxmagnitude,
    minlatitude: values.minlatitude,
    maxlatitude: values.maxlatitude,
    minlongitude: values.minlongitude,
    maxlongitude: values.maxlongitude,
    latitude: values.latitude,
    longitude: values.longitude,
    minradius: values.minradius,
    maxradius: values.maxradius,
    onlyfelt: values.onlyfelt ? 1 : 0,
    eventtype: values.eventtype,
    limit: values.limit,
    format: (values.format === 'html') ? '' : values.format,
  };

  // return the subset parameters and ignore any that are empty
  // this will prevent errors in the FDSNWS call
  const query = Object.keys(params)
    .filter(key => params[key] !== '')
    .reduce((obj, key) => {
      return {
        ...obj,
        [key]: params[key]
      };
  }, {});

  if(query.eventtype){
    query.eventtype = query.eventtype.join(',')
  }

  return query;
}


/** React component */
const EventsForm = ({
  onSubmit,
  initialValues,
}) => {
  const [ lang ] = useLanguageState();
  const [ state ] = useAppState();

  // default radius panel is expanded
  // we do this since a non-expanded leaflet map causes problems
  // in rendering the content
  const [ activeKey, setActiveKey ] = useState('radius');

  const initialFormikValues = {
    ...state.initialValues,
    ...initialValues,
  }

  const SearchSchema = Yup.object({
    starttime: Yup.date().required(),
    endtime: Yup.date().required(),
    mindepth: Yup.number().min(-5).max(1000).lessThan(Yup.ref("maxdepth")),
    maxdepth: Yup.number().min(-5).max(1000).moreThan(Yup.ref("mindepth")),
    minmagnitude: Yup.number().min(-5).max(10).lessThan(Yup.ref("maxmagnitude")),
    maxmagnitude: Yup.number().min(-5).max(10).moreThan(Yup.ref("minmagnitude")),
    eventtype: Yup.array().of(Yup.string()).required(),
    onlyfelt: Yup.boolean().required(),
    limit: Yup.number().min(1),
    format: Yup.string(),
    minlatitude: Yup.number().min(-90).max(90).lessThan(Yup.ref("maxlatitude")).required(),
    maxlatitude: Yup.number().min(-90).max(90).moreThan(Yup.ref("minlatitude")).required(),
    minlongitude: Yup.number().min(-180).max(180).lessThan(Yup.ref("maxlongitude")).required(),
    maxlongitude: Yup.number().min(-180).max(180).moreThan(Yup.ref("minlongitude")).required(),
    latitude: Yup.number().min(-90).max(90).required(),
    longitude: Yup.number().min(-180).max(180).required(),
    minradius: Yup.number().min(0).lessThan(Yup.ref("maxradius")).required(),
    maxradius: Yup.number().min(0).moreThan(Yup.ref("minradius")).required(),
  });

  // wrap the onSubmit to filter parameters we don't need and simplify certain fields
  const formikOnSubmit = (values) => {
    onSubmit(valuesToFDSNWS(values))
  }

  const formikProps = {
    initialValues: initialFormikValues,
    validationSchema: SearchSchema,
    onSubmit: formikOnSubmit,
  };

  return (
    <Formik {...formikProps}>
      {({
        handleSubmit,
      }) => (
        <Form horizontal noValidate onSubmit={handleSubmit}>
          <EventsFormRowGeneral />
          <Row>
            <Col>
              <PanelGroup
                accordion
                id="advanced-options-box"
                activeKey={activeKey}
                onSelect={setActiveKey}
              >
                <Panel eventKey="box" className="form-panel">
                  <Panel.Heading>
                    <Panel.Title toggle>{lang.form.filterBox}</Panel.Title>
                  </Panel.Heading>
                  <Panel.Body collapsible>
                    <EventsFormRowBox />
                  </Panel.Body>
                </Panel>
                <Panel eventKey="radius" className="form-panel">
                  <Panel.Heading>
                    <Panel.Title toggle>{lang.form.filterRadius}</Panel.Title>
                  </Panel.Heading>
                  <Panel.Body collapsible>
                    <p>{lang.form.radiusSearchHelp}</p>
                    <EventsFormRowRadius />
                  </Panel.Body>
                </Panel>
              </PanelGroup>
            </Col>
          </Row>
          <Button block bsStyle="primary" type="submit">{lang.form.submit}</Button>
        </Form>
      )}
    </Formik>
  )

}


EventsForm.propTypes = {
  /**
   * Callback routine for submitted values
   */
  onSubmit: PropTypes.func.isRequired,
  /**
   * Initial values of the form.
   */
  initialValues: PropTypes.shape({
    starttime: PropTypes.string,
    endtime: PropTypes.string,
    mindepth: PropTypes.number,
    maxdepth: PropTypes.number,
    minmagnitude: PropTypes.number,
    maxmagnitude: PropTypes.number,
    eventtype: PropTypes.arrayOf(PropTypes.string),
    onlyfelt: PropTypes.bool,
    limit: PropTypes.number,
    format: PropTypes.string,
    minlatitude: PropTypes.number,
    maxlatitude: PropTypes.number,
    minlongitude: PropTypes.number,
    maxlongitude: PropTypes.number,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    minradius: PropTypes.number,
    maxradius: PropTypes.number,
  })
};


EventsForm.defaultProps = {
  onSubmit: () => {},
  initialValues: {}
}


export default EventsForm;
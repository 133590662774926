import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { CircleMarker, Popup } from 'react-leaflet';
import { Table } from 'react-bootstrap';

import { useLanguageState } from '../../contexts/LanguageProvider';
import { useAppState } from '../../contexts/AppStateProvider';


/**
 * Convert magnitude to number
 * @param {number} magnitude 
 */
export const magnitudeToRadius = (magnitude) => {
  let mag = Math.floor(magnitude);
  if(mag < 1){
    mag = 1;
  }
  else if(mag > 7){
    mag = 7;
  }
  return Math.floor(mag)*2.5;
};

/**
 * Convert datetime to color
 * @param {string} datetime 
 */
export const datettimeToColor = (datetime) => {
  const curr = moment();
  let hours = moment.duration(curr.diff(moment(datetime))).asHours();
  if ( hours < 24 ) {
    return 'red';
  }
  else if ( hours < 24*7 ) {
    return 'orange';
  }
  else if ( hours < 24*30 ) {
    return 'yellow';
  }
  return 'white';
};


/** React component */
const EventsMapMarker = ({
  event,
  isLatest,
}) => {
  const [ lang ] = useLanguageState();
  const [ state ] = useAppState();

  const latitude = (event.latitude>=0) ?
    ( <span>{event.latitude.toFixed(3)}{lang.map.north}</span> ) :
    ( <span>{-event.latitude.toFixed(3)}{lang.map.south}</span> );

  const longitude = (event.longitude[0]>=0) ?
    ( <span>{event.longitude.toFixed(3)}{lang.map.east}</span> ) :
    ( <span>{(-event.longitude).toFixed(3)}{lang.map.west}</span>);

  const magnitude = event.magnitude.toFixed(1);

  return (
    <CircleMarker
      key={event.eventid}
      center={[event.latitude, event.longitude]}
      radius={magnitudeToRadius(event.magnitude)}
      fillColor={datettimeToColor(event.datetime)}
      fillOpacity={1.0}
      weight={(isLatest)? 4 : 1}
      color={(isLatest)? "#cc00ff" : "black"}
    >
      <Popup>
        <Table bordered condensed>
          <tbody>
            <tr><th>{lang.map.eventid}</th><td>{event.eventid}</td></tr>
            <tr><th>{lang.map.datetime}</th><td>{moment.utc(event.datetime).format(state.datetime.format)}</td></tr>
            <tr><th>{lang.map.magnitude}</th><td>{(event.magnitudeType)?`${magnitude} ${event.magnitudeType}`:magnitude}</td></tr>
            <tr><th>{lang.map.coordinates}</th><td>{latitude}, {longitude}</td></tr>
            <tr><th>{lang.map.depth}</th><td>{event.depth.toFixed(1)}</td></tr>
            <tr><th>{lang.map.description}</th><td>{event.getDescription(lang.abbr)}</td></tr>
          </tbody>
        </Table>
      </Popup>
    </CircleMarker>
  );
}

EventsMapMarker.propTypes = {
  /**
   * Event to display
   */
  event: PropTypes.object.isRequired,
  /**
   * Show it as the latest marker variant
   */
  isLatest: PropTypes.bool,
}

EventsMapMarker.defaultProps = {
  isLatest: false,
}

export default EventsMapMarker;
import React from 'react';
import PropTypes from 'prop-types';

import { Alert } from 'react-bootstrap';

import { useLanguageState } from '../contexts/LanguageProvider';
import { useAppState } from '../contexts/AppStateProvider';


const SubmitFeedback = ({ isLoading, isError, isOverload, isEmpty }) => {
  const [ lang ] = useLanguageState();
  const [ state ] = useAppState();

  if (isError) {

    return (
      <Alert bsStyle="danger">
        {(lang.abbr === 'fr')
          ? <p><strong>Une erreur s'est produite lors du traitement</strong>. Réessayez et, si l'erreur persiste, veuillez contacter <a href="mailto:nrcan.earthquakeinfo-infoseisme.rncan@canada.ca">Info Seisme</a>.</p>
          : <p><strong>There was an error when processing</strong>. Try again and, if the error persists, please contact <a href="mailto:nrcan.earthquakeinfo-infoseisme.rncan@canada.ca">Earthquake Info</a>.</p>
        }
      </Alert>
    );

  }
  else if (isLoading) {

    return (
      <Alert bsStyle="info">
        {(lang.abbr === 'fr')
          ? <p>Chargement des événements de notre base de données...</p>
          : <p>Loading events from our database...</p>
        }
      </Alert>
    );

  }
  else if (isOverload) {

    return (
      <Alert bsStyle="warning">
        {(lang.abbr === 'fr')
          ? <p><strong>Trop d'événements à afficher pour le Web</strong>. Pour des raisons de sécurité, nous avons limité l'affichage aux {state.maxEventsDisplay} premiers événements.</p>
          : <p><strong>Too many events to display for the web</strong>. For safety, we have limited the display to the first {state.maxEventsDisplay} events.</p>
        }
      </Alert>
    );

  }
  else if (isEmpty) {

    return (
      <Alert bsStyle="warning">
        {(lang.abbr === 'fr')
          ? <p>Aucun événement à afficher</p>
          : <p>No events to display</p>
        }
      </Alert>
    );

  }

  // if its not loading or no error, nothing to show
  return null;
};

SubmitFeedback.propTypes = {
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  isOverload: PropTypes.bool,
  isEmpty: PropTypes.bool,
}

SubmitFeedback.defaultProps = {
  isLoading: false,
  isError: false,
  isOverload: false,
  isEmpty: false,
}

export default SubmitFeedback;
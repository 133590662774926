/**
 * @module components/Form/ButtonCurrentLocation
 * @author Charles Blais <charles.blais@canada.ca>
 *
 * Button for getting current user location using Bootstrap 3
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import { useLanguageState } from '../../contexts/LanguageProvider';

/**
 * React component
 * ===============
 */
const ButtonCurrentLocation = ({
  bsStyle,
  onCoordinates,
}) => {
  const [ lang ] = useLanguageState();
  return (
    <Button
      bsStyle={bsStyle}
      style={{width:'100%'}}
      onClick={() => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            onCoordinates(position.coords.latitude, position.coords.longitude);
          });
        }
      }}
    >
      {lang.form.currentLocation}
    </Button>
  );
}

ButtonCurrentLocation.propTypes = {
  /**
   * Button background style of bootstrap 3
   */
  bsStyle: PropTypes.string,
  /**
   * Callback routine when coordinates are update
   */
  onCoordinates: PropTypes.func.isRequired,
};


ButtonCurrentLocation.defaultProps = {
  bsStyle: 'default',
  onCoordinates: () => {},
};


export default ButtonCurrentLocation;

/** @module containers/Events */

import React from 'react';
import PropTypes from 'prop-types';

import EventsMap from '../components/map/EventsMap';
import EventsSummaryTable from '../components/table/EventsSummaryTable';
import EventsTable from '../components/table/EventsTable';

const Events = ({
  events,
}) => {

  return (
    <>
      <EventsMap 
        events={events}
      />
      <EventsSummaryTable
        events={events}
      />
      <EventsTable
        events={events}
      />
    </>
  );
}

Events.propTypes = {
  events: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Events;
/**
 * @author Charles Blais
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, ControlLabel, FormControl, HelpBlock } from 'react-bootstrap';


/** React */
const EventsFormElement = ({
  id,
  label,
  controlProps,
  children,
  feedback,
  isInvalid,
}) => {
  return (
    <FormGroup controlId={id} validationState={(isInvalid)?"error":null}>
      <ControlLabel>{label}</ControlLabel>
      <FormControl {...controlProps}>
        {children}
      </FormControl>
      <FormControl.Feedback />
      { feedback && isInvalid
        ? <HelpBlock>{feedback}</HelpBlock>
        : null
      }
    </FormGroup>
  );
}

EventsFormElement.propTypes = {
  /**
   * Controller ID
   */
  id : PropTypes.string.isRequired,
  /**
   * Label text for the control
   */
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * Additional properties to be sent to FormControl element
   * of react-bootstrap v3
   */
  controlProps: PropTypes.object.isRequired,
  /**
   * Feedback information on the form element
   */
  feedback: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * FormControl children information
   */
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  /**
   * Display as invalid controller component (error occured)
   */
  isInvalid: PropTypes.bool,
}

EventsFormElement.defaultProps = {
  feedback: null,
  children: null,
  isInvalid: false,
}


export default EventsFormElement;
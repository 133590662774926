import React, { useState, useEffect } from 'react';
import './App.css';

import useAxios from 'axios-hooks';

// User-contributed library
import { xml2event } from './libs/quakeml';
import Events from './containers/Events';
import EventsSummary from './containers/EventsSummary';
import EventsSearch from './containers/EventsSearch';

import SubmitFeedback from './components/SubmitFeedback';

import { useAppState, SET_MAP_OPTIONS } from './contexts/AppStateProvider';


const App = () => {
  // set initial events
  const [ state, dispatch ] = useAppState();
  const [ events, setEvents ] = useState([]);
  const [ overload, setOverload ] = useState(false)

  // Determine application initial state
  let preload = document.getElementById('root').getAttribute('data-url');
  const displayNoTable = Boolean(document.getElementById('root').getAttribute('data-no-table'));
  const mapnofit = Boolean(document.getElementById('root').getAttribute('data-map-nofit'));
  const urlParams = new URLSearchParams(window.location.search);

  // if there are url params with any of the FDSNWS form elements, we know its also a preload
  // using the state FDSNWS web service
  if(!preload && urlParams.get('display')) {
    preload = `${state.fdsnws}/fdsnws/event/1/query${window.location.search}`
  }

  // if there is a preload, we use axios the load the data into the application
  // and convert to from xml 2 javascript friendly object
  const [{ data, loading, error, response }] = useAxios(preload, { manual: !preload })
  useEffect(() => {
    if(data) {
      xml2event(data).then(res => {
        setOverload(res.length > state.maxEventsDisplay)
        setEvents(res.slice(0, state.maxEventsDisplay))
      })
    }
  }, [data, state.maxEventsDisplay]);

  // set map properties
  useEffect(() => {
    dispatch({
      type: SET_MAP_OPTIONS,
      payload: {
        autofit: !mapnofit
      },
    });
  }, [mapnofit, dispatch]);
  
  // what type of display are we showing?
  let content = <EventsSearch />
  if(preload && displayNoTable){
    content = <EventsSummary events={events} />
  }
  else if(preload){
    content = <Events events={events} />
  }

  return (
    <>
      <SubmitFeedback
        isError={Boolean(error)}
        isLoading={Boolean(loading)}
        isOverload={overload}
        isEmpty={Boolean(response?.status === 204)}
      />
      {content}
    </>
  );
}

export default App;

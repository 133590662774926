/**
 * @author Charles Blais
 */

 import React from 'react';
 import PropTypes from 'prop-types';
 
 
 // Classes used by Leaflet to position controls
 const POSITION_CLASSES = {
   bottomleft: 'leaflet-bottom leaflet-left',
   bottomright: 'leaflet-bottom leaflet-right',
   topleft: 'leaflet-top leaflet-left',
   topright: 'leaflet-top leaflet-right',
 }
 
 const CustomControl = ({position, className, children}) => {
   const positionClass =
     (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright
   return (
     <div className={positionClass}>
       <div className={`leaflet-control leaflet-bar ${className}`}>
         {children}
       </div>
     </div>
   )
 };
 
 CustomControl.propTypes = {
   position: PropTypes.string,
   className: PropTypes.string,
 };
 
 export default CustomControl;
/**
 * @author Charles Blais
 */
import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { useFormikContext } from 'formik';

import EventsFormElement from './EventsFormElement';
import EventsFormRadiusMap from './EventsFormRadiusMap';
import ButtonCurrentLocation from './ButtonCurrentLocation';

import { useLanguageState } from '../../contexts/LanguageProvider';

const RADIUS_KM = 111

const EventsFormRowRadius = () => {
  const [ lang ] = useLanguageState();
  const formik = useFormikContext();

  const onCoordinates = (latitude, longitude) => {
    formik.setValues({
      ...formik.values,
      latitude,
      longitude,
    }, false)
  }

  return (
    <Row>
      <Col sm={3}>
        <ButtonCurrentLocation
          onCoordinates={onCoordinates}
        />
        <EventsFormElement
          id="formLatitude"
          label={lang.form.latitude}
          controlProps={{
            name: "latitude",
            type: "number",
            step: "any",
            value: formik.values.latitude,
            onChange: formik.handleChange,
          }}
          feedback={lang.form.latitudeHelp}
          isInvalid={!!formik.errors.latitude}
        />
        <EventsFormElement
          id="formLongitude"
          label={lang.form.longitude}
          controlProps={{
            name: "longitude", 
            type: "number",
            step: "any",
            value: formik.values.longitude,
            onChange: formik.handleChange,
          }}
          feedback={lang.form.longitudeHelp}
          isInvalid={!!formik.errors.longitude}
        />
        <EventsFormElement
          id="formMinRadius"
          label={lang.form.minradius}
          controlProps={{
            name: "minradius",
            type: "number",
            step: "any",
            value: formik.values.minradius * RADIUS_KM,
            onChange: (e) => formik.setFieldValue('minradius', e.target.value/RADIUS_KM),
          }}
          feedback={lang.form.minradiusHelp}
          isInvalid={!!formik.errors.minradius}
        />
        <EventsFormElement
          id="formMaxRadius"
          label={lang.form.maxradius}
          controlProps={{
            name: "maxradius",
            type: "number",
            step: "any",
            value: formik.values.maxradius * RADIUS_KM,
            onChange: (e) => formik.setFieldValue('maxradius', e.target.value/RADIUS_KM),
          }}
          feedback={lang.form.maxradiusHelp}
          isInvalid={!!formik.errors.maxradius}
        />
      </Col>
      <Col sm={9}>
        <EventsFormRadiusMap
          center={[formik.values.latitude, formik.values.longitude]}
          position={[formik.values.latitude, formik.values.longitude]}
          onCoordinates={onCoordinates}
        />
      </Col>
    </Row>
  );
}

export default EventsFormRowRadius;
/** @module containers/EventsSearc */

import React, { useMemo } from 'react';

import querystring from 'query-string';
import moment from 'moment';

import EventsForm from '../components/form/EventsForm';

import { useAppState } from '../contexts/AppStateProvider';
import { useLanguageState } from '../contexts/LanguageProvider';

/**
 * Get initial values from optional URL parameters
 */
const getInitialValues = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const result = {}
  for(const [key, value] of urlParams) {
    result[key] = value;
  }
  return result
}


const EventsSearch = () => {
  const [ state ] = useAppState();
  const [ lang ] = useLanguageState();

  const initialValues = getInitialValues();

  const timeOnLoad = useMemo(() => moment()
    .utc().format(state.datetime.format), [state.datetime.format]);

  // Convert the values from the form into a FDSNWS query
  const onSubmit = (values) => {
    if( values?.format === 'text' ) {
      values['nodata'] = 404
      const params = querystring.stringify(values);
      const url = `${state.fdsnws}/fdsnws/event/1/query?${params}`;
      window.open(url)
    } else if ( values?.format === 'quakeml' ) {
      delete values.format;
      values['nodata'] = 404
      const params = querystring.stringify(values);
      const url = `${state.fdsnws}/fdsnws/event/1/query?${params}`;
      window.open(url)
    } else {
      values['display'] = 1
      values['limit'] = values?.limit ?? (state.maxEventsDisplay + 1)
      const params = querystring.stringify(values);
      window.open(`${window.location.href}?${params}`);
    }
  }

  return (
    <>
      { lang.abbr === 'en' && 
        <details>
          <summary>Information</summary>
          <p>This form makes available seismic bulletin data from the <a href='/stndon/NEDB-BNDS/index-en.php'>National Earthquake Database (NEDB)</a> since 1985.
          As some parameters like focal depth are poorly known for most earthquakes, they are often specified only as nominal or default values.
          Before using the data, please <a href='mailto:nrcan.earthquakeinfo-infoseisme.rncan@canada.ca'>  reach us by email</a> for additional information
          on the limitations of the dataset.  Information for recent earthquakes (within the last year) should be considered preliminary, possibly incomplete,
          and subject to change.  Lastly, the information for mining-related-event and blasts may not be complete.</p>
          <p>Use of this data is subject to the <a href='/stndon/licence-en.php'>END-USER AGREEMENT FOR DIGITAL DATA</a> of the GSC.
          In addition, all users of this data are asked to include a <a href='/cite-en.php'>citation to Earthquakes Canada</a> in their work.</p>
          <hr />
          <p>Note that all queries and data use <a href='/info-gen/time-temps/utc-en.php'>Coordinated Universal Time</a> (UTC).<br />
          The current time is {timeOnLoad} UTC.</p>
          <p>Longitudes in Canada should be entered as negative values.</p>
        </details>
      }
      { lang.abbr === 'fr' && 
        <details>
          <summary>Information</summary>
          <p>Ce formulaire met des donn&eacute;es disponibles de bulletin de la <a href='/stndon/NEDB-BNDS/index-fr.php'>Base Nationale de Donn&eacute;es Sismologiques (BNDS)</a>
          depuis 1985. Comme certains param&egrave;tres tels que la profondeur focale sont mal connus pour la plupart des s&eacute;ismes, ils sont souvent
          indiqu&eacute;s par une valeur nominale ou de d&eacute;faut dans la base de donn&eacute;es.  Avant d'utiliser les donn&eacute;es, n'h&eacute;sitez
          pas &agrave;  <a href='mailto:nrcan.earthquakeinfo-infoseisme.rncan@canada.ca'>nous contacter par couriel</a> pour des renseignements suppl&eacute;mentaires
          sur les limitations du jeu de donn&eacute;es.  En outre, consid&eacute;rez l'information pour les tremblements de terre r&eacute;cents
          (de la derni&egrave;re ann&eacute;e) comme pr&eacute;liminaire, probablement incompl&egrave;te, et sujette &agrave; des changements. Enfin,
          l'information pour activit&eacute; mini&egrave;re et dynamitage peut ne pas &ecirc;tre compl&egrave;te.</p>
          <p>L'utilisation de ces donn&eacute;es est  sujette &agrave; l'<a href='/stndon/licence-fr.php'>ENTENTE D'UTILISATEUR FINAL CONCERNANT LES
          DONN&Eacute;ES NUM&Eacute;RIQUES</a> de la CGC.  En outre, tous les utilisateurs de donn&eacute;es de BNDS acceptent de mentionner
          <a href='/cite-en.php'>une citation &agrave; « S&eacute;ismes Canada » </a>dans leurs travaux.</p>
          <hr />
          <p>Veuillez noter que toutes les requêtes et données utilisent le <a href='/info-gen/time-temps/utc-fr.php'>temps universel</a> (UTC).<br/>
          Le temps actuel est {timeOnLoad} UTC</p>
          <p>Les longitudes au Canada doivent être introduites sous forme de valeurs négatives.</p>
        </details>
      }
      <EventsForm
        onSubmit={onSubmit}
        initialValues={initialValues}
      />
    </>
  );
}

export default EventsSearch;
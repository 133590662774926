/** @module containers/EventsSummary */

import React from 'react';
import PropTypes from 'prop-types';

import EventsMap from '../components/map/EventsMap';

const EventsSummary = ({
  events,
}) => {
  return (
    <EventsMap
      events={events}
    />
  );
}

EventsSummary.propTypes = {
  events: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default EventsSummary;
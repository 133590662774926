/**
 * @author Charles Blais
 */

import React from 'react';
import PropTypes from 'prop-types';

import { Table } from 'react-bootstrap';

import { useLanguageState } from '../../contexts/LanguageProvider';


/** React component */
const EventsSummaryTable = ({events}) => {
  const [ lang ] = useLanguageState();

  // summary ranges
  const ranges = [2, 2, 3, 4, 5, 6];

  // table headers
  const headers = ranges.map((value, idx, arr) => {
    let prelabel = '';
    if (idx === 0) {
      prelabel = '< ';
    } else if (idx === arr.length - 1) {
      prelabel = '≥ ';
    }
    return (<th key={`head${idx}`}>{prelabel + value}</th>);
  })

  // table content
  const content = ranges.map((value, idx, arr) => {
    let minsearch = -10;
    let maxsearch = 10;
    if (idx === 0) {
      maxsearch = value;
    } else if (idx === arr.length - 1) {
      minsearch = value;
    } else {
      minsearch = value;
      maxsearch = value + 1;
    }

    let total = events.reduce((acc, event) => {
      if (event.magnitude >= minsearch && event.magnitude < maxsearch) {
        return acc + 1;
      }
      return acc;
    }, 0);

    return (
      <td key={`cell${idx}`}>{total}</td>
    );
  });

  return (
    <Table bordered>
      <caption className='d-none'>{lang.summary.title}</caption>
      <thead>
        <tr>
          <th>{lang.summary.magnitude}</th>
          {headers}
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>{lang.summary.total}</th>
          {content}
        </tr>
      </tbody>
    </Table>
  ); 
};


EventsSummaryTable.propTypes = {
  events: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default EventsSummaryTable;

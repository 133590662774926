import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'react-bootstrap';

import CustomControl from './CustomControl';

import { useLanguageState } from '../../contexts/LanguageProvider';


/** React component */
const EventsMapLegend = ({position}) => {
  const [ lang ] = useLanguageState();

  return (
    <CustomControl className='leaflet-control-custom leaflet-control-layers' position={position}>
      <details>
        <summary>{lang.map.legend.title}</summary>
        <Row>
          <Col sm={6}>
            <p><strong>Magnitude</strong></p>
            <table>
              <tbody>
                <tr><td align="center"><span className="circle7"></span></td><td> ≥ 7</td></tr>
                <tr><td align="center"><span className="circle6"></span></td><td> 6</td></tr>
                <tr><td align="center"><span className="circle5"></span></td><td> 5</td></tr>
                <tr><td align="center"><span className="circle4"></span></td><td> 4</td></tr>
                <tr><td align="center"><span className="circle3"></span></td><td> 3</td></tr>
                <tr><td align="center"><span className="circle2"></span></td><td> 2</td></tr>
                <tr><td align="center"><span className="circle1"></span></td><td> ≤ 1</td></tr>
              </tbody>
            </table>
          </Col>
          <Col sm={6}>
            <p><strong>{lang.map.legend.age}</strong></p>
            <table>
              <tbody>
                <tr><td className="ageDay"> </td><td> {lang.map.legend.ageDay}</td></tr>
                <tr><td className="ageWeek"> </td><td> {lang.map.legend.ageWeek}</td></tr>
                <tr><td className="ageMonth"> </td><td> {lang.map.legend.ageMonth}</td></tr>
                <tr><td className="ageOlder"> </td><td> {lang.map.legend.ageOlder}</td></tr>
              </tbody>
            </table>
            <hr />
            <table>
              <tbody>
                <tr><td align="center"><span className="circleLatest"></span></td><td> {lang.map.legend.latest}</td></tr>
              </tbody>
            </table>
          </Col>
        </Row>
      </details>
    </CustomControl>
  ); 
};

EventsMapLegend.propTypes = {
  position: PropTypes.string
}

EventsMapLegend.defaultProps = {
  position: 'bottomleft'
}

export default EventsMapLegend;
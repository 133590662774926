/**
 * @module contexts/AppStateProvider
 * @author Charles Blais <charles.blais@canada.ca>
 */
import React, { createContext, useContext, useReducer } from "react";
import moment from 'moment';


export const initialState = {
  fdsnws: process.env.NODE_ENV === 'development' ? 'http://eqcan-dev.seismo.nrcan.gc.ca' : '',
  plateBoundariesUrl: process.env.NODE_ENV === 'development' ? 'plateboundaries.json' : '/apps/plateboundaries.json',
  datetime: {
    format: 'YYYY-MM-DD HH:mm:ss',
  },
  map: {
    autofit: true,
    center: [64.13, -90.34],
    zoom: 2,
  },
  // maximum of events to show for HTML
  maxEventsDisplay: 4000,
  initialValues: {
    starttime: moment().utc().subtract(1, 'months').format('YYYY-MM-DD 00:00:00'),
    endtime: moment().utc().format('YYYY-MM-DD 23:59:59'),
    mindepth: -5,
    maxdepth: 1000,
    minmagnitude: -5,
    maxmagnitude: 10,
    eventtype: ['L'],
    onlyfelt: false,
    limit: '',
    format: '',
    minlatitude: -90,
    maxlatitude: 90,
    minlongitude: -180,
    maxlongitude: 180,
    latitude: 63.13,
    longitude: -90.34,
    minradius: 0,
    maxradius: 5000/111,
  },
  // basemap assumes only 1 layer
  // the URL is defined in the language property to support french and english
  basemap: {
    proj4j: {
      name: 'EPSG:3978',
      value: '+proj=lcc +lat_1=49 +lat_2=77 +lat_0=49 +lon_0=-95 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
      // origin/resolutions is based by the values advertised for each layer of the used basemap
      origin: [-3.46558E7, 3.931E7],
      resolutions: [
        38364.660062653464,
        22489.62831258996,
        13229.193125052918,
        7937.5158750317505,
        4630.2175937685215,
        2645.8386250105837,
        1587.5031750063501,
        926.0435187537042,
        529.1677250021168,
        317.50063500127004,
        185.20870375074085,
        111.12522225044451,
        66.1459656252646,
        38.36466006265346,
        22.48962831258996,
        13.229193125052918,
        7.9375158750317505,
        4.6302175937685215,
      ],
    },
  },
}


export const SET_FDSNWS = 'SET_FDSNWS';
export const SET_MAP_OPTIONS = 'SET_MAP_OPTIONS';


const reducer = (state, action) => {
  switch (action.type) {
    case SET_FDSNWS:
      return {
        ...state,
        fdsnws: action.payload,
      }
    case SET_MAP_OPTIONS:
      return {
        ...state,
        map: {
          ...state.map,
          ...action.payload,
        },
      }
    default:
      return state;
  }
}


export const AppContext = createContext();


export const AppStateProvider = ({ initialState, children }) => (
  <AppContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </AppContext.Provider>
);


export const useAppState = () => useContext(AppContext);
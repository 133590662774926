/**
 * @author Charles Blais
 */
import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { useFormikContext } from 'formik';

import EventsFormElement from './EventsFormElement';

import { useLanguageState } from '../../contexts/LanguageProvider';

const EventsFormRowBox = () => {
  const [ lang ] = useLanguageState();
  const formik = useFormikContext();

  return (
    <Row>
      <Col sm={3}>
        <EventsFormElement
          id="formMinLatitude"
          label={lang.form.minlatitude}
          controlProps={{
            name: "minlatitude",
            type: "number",
            step: "any",
            value: formik.values.minlatitude,
            onChange: formik.handleChange,
          }}
          feedback={lang.form.minlatitudeHelp}
          isInvalid={!!formik.errors.minlatitude}
        />
      </Col>
      <Col sm={3}>
        <EventsFormElement
          id="formMaxLatitude"
          label={lang.form.maxlatitude}
          controlProps={{
            name: "maxlatitude", 
            type: "number",
            step: "any",
            value: formik.values.maxlatitude,
            onChange: formik.handleChange,
          }}
          feedback={lang.form.maxlatitudeHelp}
          isInvalid={!!formik.errors.maxlatitude}
        />
      </Col>
      <Col sm={3}>
        <EventsFormElement
          id="formMinLongitude"
          label={lang.form.minlongitude}
          controlProps={{
            name: "minlongitude",
            type: "number",
            step: "any",
            value: formik.values.minlongitude,
            onChange: formik.handleChange,
          }}
          feedback={lang.form.minlongitudeHelp}
          isInvalid={!!formik.errors.minlongitude}
        />
      </Col>
      <Col sm={3}>
        <EventsFormElement
          id="formMaxLongitude"
          label={lang.form.maxlongitude}
          controlProps={{
            name: "maxlongitude",
            type: "number",
            step: "any",
            value: formik.values.maxlongitude,
            onChange: formik.handleChange,
          }}
          feedback={lang.form.maxlongitudeHelp}
          isInvalid={!!formik.errors.maxlongitude}
        />
      </Col>
    </Row>
  );
}

export default EventsFormRowBox;
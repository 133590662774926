/**
 * @module components/MyMapContainer
 * @author Charles Blais <charles.blais@canada.ca>
 * 
 * Station map display
 */

import React from 'react';
import PropTypes from 'prop-types';

import { MapContainer, TileLayer, ScaleControl } from 'react-leaflet';
import 'proj4leaflet';
import 'react-leaflet-fullscreen-control';

import { useAppState } from '../../contexts/AppStateProvider';
import { useLanguageState } from '../../contexts/LanguageProvider';


/**
* General map container used by station/network maps
* @param {*} props 
*/
const MyMapContainer = ({children, basemap, ...props}) => {
  const [ state ] = useAppState();
  const [ lang ] = useLanguageState();

  const crs = new window.L.Proj.CRS(
    state.basemap.proj4j.name,
    state.basemap.proj4j.value,
    {
      origin: state.basemap.proj4j.origin,
      resolutions: state.basemap.proj4j.resolutions,
    },
  );

  const maxZoom = props?.maxZoom ?? state.basemap.proj4j.resolutions.length - 1;

  const url = basemap ?? lang.basemap.url

  return (
    <MapContainer
      {...props}
      crs={crs}
      minZoom={2}
      maxZoom={maxZoom}
      fullscreenControl
    >
      <TileLayer
        attribution={lang.basemap.attribution}
        url={url}
        continuousWorld={true}
      />
      {children}
      <ScaleControl position="bottomleft" />
    </MapContainer>
  );
}


MyMapContainer.propTypes = {
  /**
  * List of networks with stations
  */
  children: PropTypes.array,
  /**
   * Basemap URL
   */
  basemap: PropTypes.string,
};


MyMapContainer.defaultProps = {
  children: null,
  basemap: null,
};


export default MyMapContainer;
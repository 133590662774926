/**
 * @module contexts/LanguageProvider
 * @author Charles Blais <charles.blais@canada.ca>
 */

import React, { createContext, useContext, useReducer } from "react";


const datetimeFormatEn = (
  <span><abbr title="year">YYYY</abbr>-<abbr title="month">MM</abbr>-<abbr title="day">DD</abbr> <abbr title="hour">HH</abbr>:<abbr title="minute">MM</abbr>:<abbr title="second">SS</abbr></span>
);

const datetimeFormatFr = (
  <span>
    <abbr title="année">AAAA</abbr>-<abbr title="mois">MM</abbr>-<abbr title="jour">JJ</abbr> <abbr title="heure">HH</abbr>:<abbr title="minute">MM</abbr>:<abbr title="seconde">SS</abbr>
  </span>
);


export const initialStateEnglish = {
  abbr: 'en',
  loading: 'Loading...',
  form: {
    starttime: 'Start time (UTC)',
    starttimeHelp: datetimeFormatEn,
    regionType: 'Fill out one of the following section to limit the search of earthquakes by region or radius',
    regionTypePanelRegion: 'Limit earthquakes within a region',
    regionTypePanelRadius: 'Limit earthquakes within a radius',
    endtime: 'End time (UTC)',
    endtimeHelp: datetimeFormatEn,
    mindepth: "Depth from (km)",
    mindepthHelp: 'The minimum depth must be between -5 and 1000 km and smaller than the maximum depth',
    maxdepth: "Depth to (km)",
    maxdepthHelp:  'The maximum depth must be between -5 and 1000 km and greater than the minimum depth',
    minmagnitude: 'Magnitude from',
    minmagnitudeHelp:  'The minimum magnitude must be between -5 and 10 and smaller than the maximum magnitude',
    maxmagnitude: 'Magnitude to',
    maxmagnitudeHelp: 'The maximum magnitude must be between -5 and 10 and greater than the minimum magnitude',
    minlatitude: 'Minimum latitude',
    minlatitudeHelp:  'The minimum latitude must be between -90 and 90 degree North and smaller than the maximum latitude',
    maxlatitude: 'Maximum latitude',
    maxlatitudeHelp: 'The maximum latitude must be between -90 and 90 degree North and greater than the minimum latitude',
    minlongitude: 'Minimum longitude',
    minlongitudeHelp: 'The minimum longitude must be between -180 and 180 degree East and smaller than the maximum longitude',
    maxlongitude: 'Maximum longitude',
    maxlongitudeHelp: 'The maximum longitude must be between -180 and 180 degree East and greater than the minimum longitude',
    onlyfelt: 'Only show felt events',
    eqtypes: 'Types',
    eqtypesHelp: 'Types of events to include',
    eqtypesOptions: {
      earthquake: 'Quakes',
      'rock burst': 'Mining Events',
      explosion: 'Blasts',
      'induced or triggered event': 'Induced',
      landslide: 'Landslides',
    },
    latitude: 'Latitude',
    latitudeHelp: 'The latitude must be between -90 and 90 degree North',
    longitude: 'Longitude',
    longitudeHelp: 'The longitude must be between -180 and 180 degree East',
    minradius: <span>Minimum radius (km)</span>,
    minradiusHelp: 'The minimum radius must be greater or equal to 0 km and smaller then the maximum radius',
    maxradius: <span>Maximum radius (km)</span>,
    maxradiusHelp: 'The maximum radius must be greater or equal to 0 km and greater then the minimum radius',
    limit: 'Limit',
    limitHelp: 'Limit the amount of earthquakes returned',
    format: 'Format',
    currentLocation: 'Use my current location',
    formatOptions: {
      html: 'HTML',
      text: 'Text',
      quakeml: 'QuakeML',
    },
    formatHelp: 'The text format will open a new window',
    formatNotice: 'HTML format will automatically be limited to 4000 events',
    submit: 'Search',
    yes: 'Yes',
    no: 'No',
    filterBox: 'Filter by boxed area',
    filterRadius: 'Filter by radius area',
    others: 'Others options',
    radiusSearchHelp: 'Use your current location or enter a center latitude and longitude in the form below.  You can also move the marker on the map to select your desired latitude and longitude coordinates.'
  },
  summary: {
    title: 'Summary',
    magnitude: 'Magnitude',
    total: 'Total',
  },
  details: {
    title: 'Earthquake Details',
    datetime: (<span>Date/Time (<abbr title="Universal Time">UTC</abbr>)</span>),
    depth: 'Depth (km)',
  },
  map: {
    eventid: 'Event ID',
    datetime: (<span>Date/Time (<abbr title="Universal Time">UTC</abbr>)</span>),
    magnitude: 'Magnitude',
    earthquake: 'Earthquake',
    coordinates: 'Coordinates',
    longitude: 'Longitude (&deg;)',
    depth: (<span>Depth (km)</span>),
    description: 'Description',
    north: (<abbr title="north">N</abbr>),
    south: (<abbr title="south">S</abbr>),
    east: (<abbr title="east">E</abbr>),
    west: (<abbr title="west">W</abbr>),
    plates: "Plate boundaries",
    text: 'Place names',
    legend: {
      title: 'Legend',
      age: 'Age (past)',
      ageDay: 'day',
      ageWeek: 'week',
      ageMonth: 'month',
      ageOlder: 'older',
      latest: 'latest', 
    },
  },
  table: {
    datetime: (<span> Date/Time (<abbr title="Universal Time">UTC</abbr>)</span>),
    latitude: (<span>Latitude (&deg;)</span>),
    longitude: (<span>Longitude (&deg;)</span>),
    depth: 'Depth (km)',
    magnitude: 'Magnitude',
    description: 'Description',
  },
  basemap: {
    url: "https://maps-cartes.services.geo.ca/server2_serveur2/rest/services/BaseMaps/CBMT3978/MapServer/tile/{z}/{y}/{x}",
    url_no_text: "https://maps-cartes.services.geo.ca/server2_serveur2/rest/services/BaseMaps/CBMT_CBCT_GEOM_3978/MapServer/tile/{z}/{y}/{x}",
    url_text: "https://maps-cartes.services.geo.ca/server2_serveur2/rest/services/BaseMaps/CBMT_TXT_3978/MapServer/tile/{z}/{y}/{x}",
    attribution: "&#169; His Majesty the King in Right of Canada, as represented by the Minister of Natural Resources",
  },
};



export const initialStateFrench = {
  abbr: 'fr',
  loading: 'Chargement...',
  form: {
    starttime: 'Date de début (UTC)',
    starttimeHelp: datetimeFormatFr,
    regionType: "Remplissez l'une des sections suivantes pour limiter la recherche de tremblements de terre par région ou rayon",
    regionTypePanelRegion: 'Limiter les tremblements de terre dans une région',
    regionTypePanelRadius: 'Limiter les tremblements de terre dans un rayon',
    endtime: 'Date de terminaison (UTC)',
    endtimeHelp: datetimeFormatFr,
    mindepth: "Profondeur de (km)",
    mindepthHelp: 'La profondeur minimale doit être entre -5 et 1000 km et plus petit que la profondeur maximale',
    maxdepth: "Profondeur à (km)",
    maxdepthHelp: 'La profondeur maximale doit être entre -5 et 1000 km et plus grand que la profondeur minimale',
    minmagnitude: 'Magnitude de',
    minmagnitudeHelp: 'La magnitude minimale doit être entre -5 et 10 et plus petit que la magnitude maximale',
    maxmagnitude: 'Magnitude à',
    maxmagnitudeHelp: 'La magnitude maximale doit être entre -5 et 10 et plus grand que la magnitude minimale',
    minlatitude: 'Latitude minimale',
    minlatitudeHelp: 'La latitude minimale doit être entre -90 et 90 degré Nord et plus petit que la latitude maximale',
    maxlatitude: 'Latitude maximale',
    maxlatitudeHelp: 'La latitude maximale doit être entre -90 et 90 degré Nord et plus grand que la latitude minimale',
    minlongitude: 'Longitude minimale',
    minlongitudeHelp: 'La longitude minimale doit être entre -180 et 180 degré Est et plus petit que la longitude maximale',
    maxlongitude: 'Longitude maximale',
    maxlongitudeHelp: 'La longitude maximale doit être entre -180 et 180 degré Est et plus grand que la longitude minimale',
    onlyfelt: 'Montrez seulement seut ressenti',
    eqtypes: 'Types',
    eqtypesHelp: 'Types d\'événements à inclure',
    eqtypesOptions: {
      earthquake: 'Séisme',
      'rock burst': 'Événements miniers',
      explosion: 'Dynamitage',
      'induced or triggered event': 'Induit',
      landslide: 'Glissement de terrain',
    },
    latitude: 'Latitude',
    latitudeHelp: 'La latitude doit être entre -90 et 90 degré Nord',
    longitude: 'Longitude',
    longitudeHelp: 'La longitude doit être entre -180 et 180 degré Est',
    minradius: <span>Rayons minimale (km)</span>,
    minradiusHelp: 'Le rayon minimum doit être supérieur ou égal à 0 km et inférieur au rayon maximum',
    maxradius: <span>Rayon maximale (km)</span>,
    maxradiusHelp: 'Le rayon maximum doit être supérieur ou égal à 0 km et supérieur au rayon minimum',
    limit: 'Limite',
    limitHelp: 'Limiter le nombre de séismes retourner',
    format: 'Format',
    currentLocation: 'Utiliser ma position actuelle',
    formatOptions: {
      html: 'HTML',
      text: 'Texte',
      quakeml: 'QuakeML',
    },
    formatHelp: 'Le format texte ouvrira une nouvelle fenêtre',
    formatNotice: 'Le format HTML sera automatiquement limité à 4000 événements',
    submit: 'Recherche',
    yes: 'Oui',
    no: 'Non',
    filterBox: 'Filtrer par zone encadrée',
    filterRadius: 'Filtrer par zone de rayon',
    others: 'Autres options',
    radiusSearchHelp: 'Utilisez votre position actuelle ou entrez une latitude et une longitude du centre dans le formulaire ci-dessous. Vous pouvez également déplacer le marqueur sur la carte pour sélectionner les coordonnées de latitude et de longitude souhaitées.'
  },
  summary: {
    title: 'Sommaire',
    magnitude: 'Magnitude',
    total: 'Totale',
  },
  details: {
    title: 'Détails du séisme',
    datetime: (<span>Date/Heure (<abbr title="Temps Universel">UTC</abbr>)</span>),
    depth: 'Profondeur (km)',
  },
  map: {
    eventid: "ID de l'événement",
    datetime: (<span>Date/Heure (<abbr title="Temps Universel">UTC</abbr>)</span>),
    magnitude: 'Magnitude',
    earthquake: 'Séisme',
    coordinates: 'Coordonnées',
    longitude: 'Longitude (&deg;)',
    depth: (<span>Profondeur (km)</span>),
    description: 'Description',
    north: (<abbr title="Nord">N</abbr>),
    south: (<abbr title="Sud">S</abbr>),
    east: (<abbr title="Est">E</abbr>),
    west: (<abbr title="Ouest">O</abbr>),
    plates: "Limites des plaques",
    text: 'Noms de lieux',
    legend: {
      title: 'Légende',
      age: 'Age (passé)',
      ageDay: 'journée',
      ageWeek: 'semaine',
      ageMonth: 'mois',
      ageOlder: 'plus âgée',
      latest: 'dernier',
    },
  },
  table: {
    datetime: (<span>Date/Heure (<abbr title="Temps Universel">UTC</abbr>)</span>),
    latitude: (<span>Latitude (&deg;)</span>),
    longitude: (<span>Longitude (&deg;)</span>),
    depth: 'Profondeur (km)',
    magnitude: 'Magnitude',
    description: 'Description',
  },
  basemap: {
    url: "https://maps-cartes.services.geo.ca/server2_serveur2/rest/services/BaseMaps/CBCT3978/MapServer/tile/{z}/{y}/{x}",
    url_no_text: "https://maps-cartes.services.geo.ca/server2_serveur2/rest/services/BaseMaps/CBMT_CBCT_GEOM_3978/MapServer/tile/{z}/{y}/{x}",
    url_text: "https://maps-cartes.services.geo.ca/server2_serveur2/rest/services/BaseMaps/CBCT_TXT_3978/MapServer/tile/{z}/{y}/{x}",
    attribution: "&#169; Sa Majesté le Rois du Chef du Canada, représentée par le ministre des Ressources naturelles",
  },
};


const reducer = (state, action) => {
  switch (action.type) {
    default:
      return state;
  }
}


export const LanguageContext = createContext();


export const LanguageProvider = ({ initialState, children }) => (
  <LanguageContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </LanguageContext.Provider>
);


export const useLanguageState = () => useContext(LanguageContext);
import React, { useMemo, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import useAxios from 'axios-hooks';

import { useMap, LayersControl, TileLayer, GeoJSON, FeatureGroup } from 'react-leaflet';

// User contributed modules
import MyMapContainer from './MyMapContainer';
import EventsMapMarker from './EventsMapMarker';
import EventsMapLegend from './EventsMapLegend';

import { useAppState } from '../../contexts/AppStateProvider';
import { useLanguageState } from '../../contexts/LanguageProvider';


/** React component */
const ChangeView = ({ center, zoom }) => {
  const map = useMap();
  map.setView(center, zoom);
  return null;
}

/** React component */
const EventsMap = ({
  events,
  center,
  zoom,
}) => {
  const [ state ] = useAppState();
  const [ lang ] = useLanguageState();
  const [ map, setMap ] = useState(null);
  const groupRef = useRef(null);

  // load plate boundaries informaiton
  const [{ data: plateboundaries }] = useAxios(state.plateBoundariesUrl);

  // before converting the data lets sort the array in reverse order
  // by datetime
  const sortedEvents = useMemo(() => {
    return events.sort((a, b) => {
      return (moment(a.datetime).isAfter(moment(b.datetime)))?1:-1
    });
  }, [events]);

  // Get the latest event
  let latest = {};
  if (sortedEvents.length) {
    latest = sortedEvents[sortedEvents.length - 1];
  }

  const markers = sortedEvents.map((event) => (
    <EventsMapMarker
      key={event.eventid}
      event={event}
      isLatest={event.eventid === latest.eventid}
    />
  ));

  useEffect(() => {
    if( map && groupRef.current ){
      const bounds = groupRef.current.getBounds();
      if(bounds.isValid() && state.map.autofit) map.fitBounds(bounds, {padding: [100, 100]})
    }
  }, [map, groupRef, events, state.map.autofit])


  return (
    <MyMapContainer
      className="leaflet-events-map"
      center={center}
      zoom={zoom}
      maxZoom={10}
      basemap={lang.basemap.url_no_text}
      whenCreated={(map) => setMap(map)}
    >
      <ChangeView center={center} zoom={zoom} />
      <LayersControl>
        { plateboundaries &&
          <LayersControl.Overlay name={lang.map.plates}>
            <GeoJSON data={plateboundaries} style={{weight:1}}/>
          </LayersControl.Overlay>
        }
        <LayersControl.Overlay checked={true} name={lang.map.text}>
          <TileLayer
            attribution={lang.basemap.attribution}
            url={lang.basemap.url_text}
            continuousWorld={true}
          />
        </LayersControl.Overlay>
      </LayersControl>
      <FeatureGroup ref={groupRef}>
        {markers}
      </FeatureGroup>
      <EventsMapLegend position="bottomright" />
    </MyMapContainer>
  ); 
};

EventsMap.propTypes = {
  events: PropTypes.arrayOf(PropTypes.object),
  center: PropTypes.arrayOf(PropTypes.number),
  zoom: PropTypes.number,
  noAutoFit: PropTypes.bool,
}

EventsMap.defaultProps = {
  events: [],
  center: [63.13, -90.34],
  zoom: 3,
  noAutoFit: false
}

export default EventsMap;
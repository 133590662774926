/**
 * @author Charles Blais
 */


import xml2js from 'xml2js';


export class Event {
  /**
   * Constructor
   *
   * @constructor
   * @param {Object} data - event object as returned by xml2js decoding
   */
  constructor(data) {
    this._data = data;
  }
  
  /**
   * The eventID can be coded in several ways under the publicID attribute.
   * In this case, we decode the IRIS or SC3 eventID format.
   *
   *   1. IRIS: url/query?eventid=[eventid]
   *   2. SC3: url/[eventid]
   *
   * @return {string} event id
   */
  get eventid() {
    const splitPattern = this._data.$.publicID.indexOf('eventid=') >= 0 ? 'eventid=' : '/';
    const id = this._data.$.publicID.split(splitPattern);
    return id[id.length - 1];
  }
  
  /**
   * Getter
   * @return {string} datetime
   */
  get datetime() { return this._data.origin[0].time[0].value[0]; }

  /**
   * Getter
   * @return {number} latitude
   */
  get latitude() { return parseFloat(this._data.origin[0].latitude[0].value[0]); }

  /**
   * Getter
   * @return {number} lnngitude
   */
  get longitude() { return parseFloat(this._data.origin[0].longitude[0].value[0]); }

  /**
   * Getter
   * @return {number} depth
   */
  get depth() {
    return this._data.origin[0].depth ? parseFloat(this._data.origin[0].depth[0].value[0]) : null;
  }

  /**
   * Getter
   * @return {number} magnitude
   */
  get magnitude() { return parseFloat(this._data.magnitude[0].mag[0].value[0]); }

  /**
   * Getter
   * @return {string} magnitude type
   */
  get magnitudeType() {
    return (this._data.magnitude[0].type) ? this._data.magnitude[0].type[0] : null;
  }

  /**
   * Getter
   * @return {string} description
   */
  get description() {
    const desc = this._data.description.find(desc => {
      return desc.type[0] === 'earthquake name';
    });
    return (desc) ? desc.text[0] : '';
  }

  /**
   * Alternative version of get description with language specific
   * @param {str} lang 
   */
  getDescription(lang = 'en') {
    const subdescr = this.description.split('/');
    if (subdescr.length === 2) {
      return (lang === 'fr') ? subdescr[1] : subdescr[0];
    }
    return this.description;
  }

  /**
   * Convert class into an object that is immutable (for redux)
   * @return {Object} event as object
   */
  serialize() {
    const proto = Object.getPrototypeOf(this);
    const getters = Object.getOwnPropertyNames(proto).reduce((accumulator, prop) => {
      const descriptor = Object.getOwnPropertyDescriptor(proto, prop);
      if (typeof descriptor.get === 'function') {
        return Object.assign({}, accumulator, { [prop]: this[prop] });
      }
      return accumulator;
    }, {});
    return getters;
  }
}
  
  
/** QuakeML class */
export class QuakeML {
  /**
   * Constructor
   *
   * @constructor
   * @param {Object} data - xml2js QuakeML response
   * @throws Error
   */
  constructor(data) {
    if (!('q:quakeml' in data)) {
      throw new Error('q:quakeml key does not exist in QuakeML, can not convert');
    }
    if (!('eventParameters' in data['q:quakeml'])) {
      throw new Error('eventParameters key does not exist in QuakeML, can not convert');
    }

    // Reduce the data by removing the root
    const eventParameters = data['q:quakeml'].eventParameters[0];

    // Error thrown if there are no even (invalid format)
    if (!('event' in eventParameters)) {
      throw new Error('There are no event in QuakeML');
    }

    // Convert all the events into Event object
    const events = eventParameters.event.map(event => new Event(event));
    this._data = events;
  }

  /**
   * Getter
   * @return {Object[]} events
   */
  get events() { return this._data; }

  /**
   * Convert class into an object that is immutable (for redux)
   * @return {Object} quakeml as object
   */
  serialize() {
    return { events: this.events.map(event => event.serialize()) };
  }
}


export const xml2event = async xml => {
  const data = await xml2js.parseStringPromise(xml)
  if( data == null) return [];
  const qml = new QuakeML(data);
  return qml.events
}
